import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {topConnect} from '../../environments/environment';
/* eslint-disable */
@Injectable({
  providedIn: 'root'
})
export class StickerService {

  constructor(
    public http: HttpClient) { }
  
    public getStickerLogged(): Observable<any>{
  
      let headers = new HttpHeaders({
		  
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('access_token')
      })
      let stickerId:string = localStorage.getItem('stickerId')!;
      return this.http.get(`${topConnect.apiUrl}stickers/${stickerId}`,{headers:headers});
      
    }

    public postStickersId(){
      let stickerId:string = localStorage.getItem('stickerId')!;
      let params = {ids:[stickerId]};
  		const headers = new HttpHeaders({
        'Content-Type':'application/json',
        'Authorization': localStorage.getItem('access_token')
      });

  	return new Promise((resolve,rejected) => {
      let companyId:string = localStorage.getItem('companyId')!;
  		this.http.post<any>(topConnect.apiUrl+`/empresas/${companyId}/localidades/filter/`, params, {headers: headers})
  		.subscribe(
        
  		        (data:any) => {
  		        	resolve(data);
  		            console.log("POST call successful value returned in body", 
  		                        data);
  		        },
  		        response => {
  		        	rejected(response);
  		            console.log("POST call in error", response);
  		        },
  		        () => {
  		            console.log("The POST observable is now completed.");
  		        });
  	});
  
    }
}
