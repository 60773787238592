import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BusquedasService } from 'src/app/service/busquedas.service';
import { LangMonthService } from 'src/app/service/lang-month.service';
import { FilterService } from 'src/app/service/filter.service';
import { CompanyService } from 'src/app/service/company.service';
import jwt_decode from 'jwt-decode';
import { DrawStatsService} from 'src/app/service/draw-stats.service';
import * as dayjs from 'dayjs';
import { TranslateService } from '@ngx-translate/core';
import { OptionDateInput } from './navegador.model';
import {StickerService} from 'src/app/service/sticker.service';
/* eslint-disable */
@Component({
  selector: 'app-navegador',
  templateUrl: './navegador.component.html',
  styleUrls: ['./navegador.component.scss']
})
export class NavegadorComponent implements OnInit {
  public name: string;
  public optionDateSelect: string;
  public date: string;
  public drawStats: DrawStatsService;
  public selectDates: Array<{name: string; year: number; value: string}>;
  public company: {_id: string; nombre: string};
  public sticker: {_id: string; name: string};
  public brands: Array<{_id: string; nombre: string}>;
  public stickers: Array<{_id: string; name: string}>;
  public locationsIds: Array<{_id: string; codigo_tienda: string}>;
  public optionCompanySelect: string;
  public optionBrandSelect: string;
  public optionStickerSelect: string;
  public optionLocationsSelect: string;
  public optionLangSelect: any;
  public activeLang = 'es';
  private months: string[] = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
    'Agosto', 'Septiembre', 'Octubre', 'Noviembre','Diciembre'
  ];
  // private now: dayjs.Dayjs = dayjs().subtract(1, 'month');
  constructor(
    public router: Router,
    public busquedasService: BusquedasService,
    public langMonthService: LangMonthService,
    public companyService: CompanyService,
    public stickerService: StickerService,
    private filter: FilterService,
    private drawStatsService: DrawStatsService,
    private translate: TranslateService
    ) {
    this.drawStats = new DrawStatsService();
    this.name = '';
    this.selectDates = this.getSelectDatesInfo();
    this.company = {_id: '0', nombre: ''};
    this.sticker = {_id: '0', name: ''};
    this.brands = [{_id: '0', nombre: ''}];
    this.stickers = [{_id: '0', name: ''}];
    this.locationsIds = [{_id: '0', codigo_tienda: 'Tiendas'}];
    this.optionCompanySelect = '';
    this.optionBrandSelect = '';
    this.optionStickerSelect = '';
    this.optionLocationsSelect = '';
    this.optionDateSelect = this.funcionMinorDateEight();
    this.optionLangSelect = this.translate.setDefaultLang(this.activeLang);
    }

  ngOnInit(): void {
    console.log("iniciando..")
    if(this.date === '0'){
      let dateCompare = dayjs().subtract(1, 'month').format('DD');
      if (dateCompare < "07"){
        const now : dayjs.Dayjs = dayjs().subtract(2, 'month');
        this.optionDateSelect = now.format('YYYY-MM-DD')
        now.format('YYYY-MM-DD')
      }else{
        let now: dayjs.Dayjs = dayjs().subtract(1, 'month');
        this.optionDateSelect = now.format('YYYY-MM-DD')
        now.format('YYYY-MM-DD');
      }
    }
      const month = this.captureDateSelect();
    // const newDate = new Date(month);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const token: string = localStorage.getItem('access_token')!;
    const decoded: any = jwt_decode(token);
    this.companyService.getCompanyLogged().subscribe((data) => {
      this.company = data;
      this.stickers = data.stickers;
      this.optionStickerSelect = this.filter.getStickerCurrentValue();
      this.optionBrandSelect  = this.filter.getBrandCurrentValue();
      this.optionLangSelect = this.filter.getLangCurrentValue();
      this.companyService.getBrandsByCompanyLogged().subscribe((brands) => {
        this.brands = brands;
      });
    });
    this.stickerService.getStickerLogged().subscribe((data) => {
      this.sticker = data;
      this.optionLangSelect = this.filter.getLangCurrentValue();
      this.optionLocationsSelect = this.filter.getLocationCurrentValue();
      this.stickerService.postStickersId().then(res => {
        let resi = res as any
        for(let i in resi){
          let newObject={
            _id: res[i]._id,
            codigo_tienda: res[i].codigo_tienda
          };
          this.locationsIds.push(newObject)
        }
        
      })
    });

  }

  public cambiarLenguaje(lang: any) {
    this.activeLang = lang;
    this.translate.use(lang);
    this.captureLangSelect(lang);
  }
  captureDateSelect() {
      const month = this.optionDateSelect;
      this.filter.changeDate(month);
      return month;
  }
  funcionMinorDateEight(){
      this.filter.customDate.subscribe((date) =>{
        this.date = date
      });
      return this.date
    
  }

  captureCompanySelect() {
    const company = this.optionCompanySelect;
    this.filter.changeCompany(company);
    return company;
  }

  captureBrandSelect() {
    const brand = this.optionBrandSelect;
    this.optionStickerSelect='0';
    this.filter.changeBrand(brand);
    this.filter.changeSticker('0');
    return brand;
  }
  captureStickerSelect(){
    const sticker = this.optionStickerSelect;
    this.optionBrandSelect = '0';
    this.filter.changeSticker(sticker);
    this.filter.changeBrand('0');
    return sticker;
  }
  captureLocationSelect(){
    this.drawStats.addSpinner();
    let location = this.optionLocationsSelect;
    this.filter.changeLocation(location);
    return location;
  }
  captureLangSelect(lang: any){
    this.filter.changeLang(lang);
    return lang;
  }

  logOut(){
    localStorage.removeItem('access_token');
    this.router.navigate(['']);
  }
  goMap(){
    this.router.navigate(['mapas']);
  }
  goHistory(){
    this.router.navigate(['historico']);
  }
  returnToPanel(){
    this.router.navigate(['panel']);
  };

  getSelectDatesInfo(){
    const dateInfo = [];
    for(let i = 1; i<=12; i++) {
      const dayJsDataMonth = dayjs().subtract(i, 'month');
      dateInfo.push({
        name: this.months[dayJsDataMonth.month()],
        year: dayJsDataMonth.format('YYYY'),
        value: dayJsDataMonth.format('YYYY-MM-DD'),
      });
    }
    let dateCompareDay = dayjs().subtract(1, 'month').format('DD')
    if (dateCompareDay < "07" ){
      dateInfo.shift()
    }
    return dateInfo;
  }
}
