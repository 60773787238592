<div class="cortina" id="cortina">
    <span class="spinner hidden" id="loaderSpinner"></span>
<app-navegador></app-navegador>

<h1 class="text-center titulo">{{'reputation' | translate}}</h1>

  <div class="container-fluid">
      <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div id="canvasGlobal" class="col-md-6 chart">
                        <canvas id="chartReputationGlobal" class="chartReputation" width="620" height="400"></canvas>
                        <h1 [ngClass]="drawStats.getColorAverage(stats.global.average)">{{stats.global.average}}</h1>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12 background text-center">
                                <h4 class="bold">{{'reviews-total-global' | translate}}</h4>
                                <h1 class="font bold">{{stats.global.total}}</h1>
                            </div>
                        </div>
                            <div class="row mt-3">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <p class="top-font-reputation-4"><img class="img-reputacion" src="../assets/img/typology/typology_positive.png" alt="">{{'positive' | translate}}</p>
                                        </div>
                                        <div class="col-md-3">
                                            <p class="green-reputation top-font-4">{{stats.global.good.value}}</p>
                                        </div>
                                        <div class="col-md-3">
                                            <p class="top-font-4">/{{stats.global.good.percentage}}%</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12"> 
                                    <div class="row">
                                        <div class="col-md-6">
                                            <p class="top-font-reputation-4"><img class="img-reputacion" src="../assets/img/typology/typology_neutral.png" alt="">{{'neutral' | translate}}</p>
                                        </div>
                                        <div class="col-md-3">
                                            <p class="yellow-reputation top-font-4">{{stats.global.neutral.value}}</p>
                                        </div>
                                        <div class="col-md-3">
                                            <p class="top-font-4">/{{stats.global.neutral.percentage}}%</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <p class="top-font-reputation-4"><img class="img-reputacion" src="../assets/img/typology/typology_negative.png" alt="">{{'negative' | translate}}</p>
                                        </div>
                                        <div class="col-md-3">
                                            <p class="red-reputation top-font-4">{{stats.global.bad.value}}</p>
                                        </div>
                                        <div class="col-md-3">
                                            <p class="top-font-4">/{{stats.global.bad.percentage}}%</p>
                                            <span class="spinner hidden" id="loaderSpinner"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="row">
                    <div id="canvasMonth" class="col-md-6 chart">
                        <canvas id="chartReputationMonth" class="chartReputation"  width="620" height="400"></canvas> 
                        <h1 [ngClass]="drawStats.getColorAverage(stats.month.average)">{{stats.month.average}}</h1>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12 background  text-center">
                                <h4 class="bold">{{'reviews-total-month' | translate}}</h4>
                                <h1 class="font bold">{{stats.month.total}}</h1>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-6">
                                        <p class="top-font-reputation-4"><img class="img-reputacion" src="../assets/img/typology/typology_positive.png" alt="">{{'positive' | translate}}</p>
                                    </div>
                                    <div class="col-md-3">
                                        <p class="green-reputation top-font-4">{{stats.month.good.value}}</p>
                                    </div>
                                    <div class="col-md-3">
                                        <p class="top-font-4">/{{stats.month.good.percentage}}%</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12"> 
                                <div class="row">
                                    <div class="col-md-6">
                                        <p class="top-font-reputation-4"><img class="img-reputacion" src="../assets/img/typology/typology_neutral.png" alt="">{{'neutral' | translate}}</p>
                                    </div>
                                    <div class="col-md-3">
                                        <p class="yellow-reputation top-font-4">{{stats.month.neutral.value}}</p>
                                    </div>
                                    <div class="col-md-3">
                                        <p class="top-font-4">/{{stats.month.neutral.percentage}}%</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-6">
                                        <p class="top-font-reputation-4"><img class="img-reputacion" src="../assets/img/typology/typology_negative.png" alt="">{{'negative' | translate}}</p>
                                    </div>
                                    <div class="col-md-3">
                                        <p class="red-reputation top-font-4">{{stats.month.bad.value}}</p>
                                    </div>
                                    <div class="col-md-3">
                                        <p class="top-font-4">/{{stats.month.bad.percentage}}%</p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
      </div>
  </div>

<div class="container-fluid mt-5">
    <div [ngClass]="drawStats.getClassBylocation(stats.month.table_no_top)" class="row m-4">
        <div class="col">
                <table class="reputation-ranking" border=0 cellspacing="0" cellpadding="0">
                    <thead>
                        <th>Top</th>
                        <th>{{'code' | translate}}</th>
                        <th>{{'description' | translate}}</th>
                        <th>{{'average-short' | translate}}</th>
                        <th>{{'reviews' | translate}}</th>
                    </thead>
                    <tbody>
                        <tr  *ngFor="let stat of stats.global.table_top">
                            <th [ngClass]="drawStats.getColor(stat.score)">{{stat.label}}</th>
                            <td [ngClass]="drawStats.getColor(stat.score)">{{stat.code}}</td>
                            <td [ngClass]="drawStats.getColor(stat.score)">{{stat.description}}</td>
                            <td [ngClass]="drawStats.getColor(stat.score)">{{stat.score}}</td>
                            <td [ngClass]="drawStats.getColor(stat.score)">{{stat.reviews}}</td>
                        </tr>
                    </tbody>
                </table>
        </div>
        <div class="col">
                <table class="reputation-ranking" border=0 cellspacing="0" cellpadding="0">
                    <thead>
                        <th>Top</th>
                        <th>{{'code' | translate}}</th>
                        <th>{{'description' | translate}}</th>
                        <th>{{'average-short' | translate}}</th>
                        <th>{{'reviews' | translate}}</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let stat of stats.global.table_no_top">
                            <th [ngClass]="drawStats.getColor(stat.score)">{{stat.label}}</th>
                            <td [ngClass]="drawStats.getColor(stat.score)">{{stat.code}}</td>
                            <td [ngClass]="drawStats.getColor(stat.score)">{{stat.description}}</td>
                            <td [ngClass]="drawStats.getColor(stat.score)">{{stat.score}}</td>
                            <td [ngClass]="drawStats.getColor(stat.score)">{{stat.reviews}}</td>
                        </tr>
                    </tbody>
                </table>
        </div>
            <div class="col">
                    <table class="reputation-ranking" border=0 cellspacing="0" cellpadding="0">
                        <thead>
                            <th>Top</th>
                            <th>{{'code' | translate}}</th>
                            <th>{{'description' | translate}}</th>
                            <th>{{'average-short' | translate}}</th>
                            <th>{{'reviews' | translate}}</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let stat of stats.month.table_top">
                                <th [ngClass]="drawStats.getColor(stat.score)">{{stat.label}}</th>
                                <td [ngClass]="drawStats.getColor(stat.score)">{{stat.code}}</td>
                                <td [ngClass]="drawStats.getColor(stat.score)">{{stat.description}}</td>
                                <td [ngClass]="drawStats.getColor(stat.score)">{{stat.score}}</td>
                                <td [ngClass]="drawStats.getColor(stat.score)">{{stat.reviews}}</td>
                            </tr>
                        </tbody>
                    </table>
            </div>
                <div class="col">
                        <table class="reputation-ranking" border=0 cellspacing="0" cellpadding="0">
                            <thead>
                                <th>Top</th>
                                <th>{{'code' | translate}}</th>
                                <th>{{'description' | translate}}</th>
                                <th>{{'average-short' | translate}}</th>
                                <th>{{'reviews' | translate}}</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let stat of stats.month.table_no_top">
                                    <th [ngClass]="drawStats.getColor(stat.score)">{{stat.label}}</th>
                                    <td [ngClass]="drawStats.getColor(stat.score)">{{stat.code}}</td>
                                    <td [ngClass]="drawStats.getColor(stat.score)">{{stat.description}}</td>
                                    <td [ngClass]="drawStats.getColor(stat.score)">{{stat.score}}</td>
                                    <td [ngClass]="drawStats.getColor(stat.score)">{{stat.reviews}}</td>
                                </tr>
                            </tbody>
                        </table>
                </div>
</div>
</div>