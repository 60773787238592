import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {topConnect} from '../../environments/environment'
/* eslint-disable */

@Injectable({
  providedIn: 'root'
})

export class BusquedasService {
  
constructor(
  private http: HttpClient) { }

  public getInfo(month:number,year:number,id:string,type:string): Observable<any>{
    
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      //'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    })
    if (id=="0"){
      type = "sticker";
      id = localStorage.getItem('stickerId')!;
    }  
    return this.http.get<any>(`${topConnect.backUrl}/searchs/${type}/${id}/${year}/${month}/`,{headers:headers});
  }

}
