import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './components/login/login.component';
import { PanelComponent } from './components/panel/panel.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BusquedasComponent } from './components/busquedas/busquedas.component';
import { VisionadosComponent } from './components/visionados/visionados.component';
import { AccionesComponent } from './components/acciones/acciones.component';
import { ReputacionComponent } from './components/reputacion/reputacion.component';
import { AnalisisComponent } from './components/analisis/analisis.component';
import { NavegadorComponent } from './components/navegador/navegador.component';
import { MapComponent } from './components/map/map.component';
import { HistoricoComponent } from './components/historico/historico.component';
import { LoginTokenComponent } from './components/login-token/login-token.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RouterModule } from '@angular/router';







@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PanelComponent,
    BusquedasComponent,
    VisionadosComponent,
    AccionesComponent,
    ReputacionComponent,
    AnalisisComponent,
    NavegadorComponent,
    MapComponent,
    HistoricoComponent,
    LoginTokenComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [ HttpClient ]
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
