import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class D3ServiceService {

  constructor(
    private http: HttpClient
  ) { }
    
    public getInfo(): Observable<any>{

      return this.http.get("https://raw.githubusercontent.com/codeforamerica/click_that_hood/master/public/data/spain-provinces.geojson");
    
    }

    // d3.json(url, function(spain:any){
    //     var group = svg.selectAll("g")  // Creamos un grupo para cada provincia
    //         .data(spain.features)
    //         .enter()
    //         .append("g");
  
    //     // Para cada grupo añadimos el path correspondiente
    //     var areas = group.append("path")
    //         .attr("class", "province");
  
    // });
  
      
}
