import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Chart from 'chart.js';
import { FilterService } from 'src/app/service/filter.service';
import { LangMonthService } from 'src/app/service/lang-month.service';
import {StatsAnualesService} from 'src/app/service/stats-anuales.service';
import { Visionados } from './visionados.model';
import {VisionadosService} from 'src/app/service/visionados.service';
import { DrawStatsService} from 'src/app/service/draw-stats.service';
import { Subscription } from 'rxjs';
/* eslint-disable */
@Component({
  selector: 'app-visionados',
  templateUrl: './visionados.component.html',
  styleUrls: ['./visionados.component.scss']
})
export class VisionadosComponent implements OnInit {
  canvas: any;
  newCanvas: any;
  chartUpdate: any;
  divCanvas: any;
  ctx: any;
  stats : Visionados;
  date: string;
  editDate: string;
  drawStats: DrawStatsService;
  public customBrandSubscription: Subscription;
  public customDateSubscription: Subscription;
  public customLocationSubscription: Subscription;
  public customStickerSubscription: Subscription;
  public customLangSubscription: Subscription;

  constructor(
    public router: Router,
    public langMonthService: LangMonthService,
    public statsAnualesService: StatsAnualesService,
    public visionadosService: VisionadosService,
    private drawStatsService: DrawStatsService,
    private filter: FilterService
    ) {
      if (!localStorage.getItem('access_token')){
        this.router.navigate(['']);
      }
    this.drawStats = new DrawStatsService(); 
    this.stats= this.getStatsDefault()
   }

  ngOnInit(): void {
    this.customDateSubscription = this.filter.customDate.subscribe(date => {
      if (date!=='0'){
      this.drawStats.addSpinner();
      let newDate = new Date(date);
      let id = this.filter.getBrandCurrentValue();
      let type = this.filter.getTypeCurrentValue();
      let lang = this.filter.getLangCurrentValue();
      let idLocation = this.filter.getLocationCurrentValue();
      if(idLocation == '0'){
        this.visionadosService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),id,"sticker").subscribe((dataStats) => {
          if(dataStats.data === null){
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }else{
            this.fillVisionados(dataStats);
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }
        });
      }else{
        this.statsAnualesService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),idLocation).subscribe((dataStats) => {
          if(dataStats.data === null){
           
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }else{
           
            this.fillVisionadosLocations(dataStats);
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }
        });
      }
    }
    });
    this.customLocationSubscription = this.filter.customLocation.subscribe(locationId => {
      if (locationId!="0"){
      let date:string = this.filter.getDateCurrentValue();
      let newDate = new Date(date);
      let lang = this.filter.getLangCurrentValue();
      let month = newDate.getMonth()+1
      let varLocation = 'location_'+locationId+'-'+month+'-'+newDate.getFullYear();
      this.statsAnualesService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),locationId).subscribe((dataStats) => {
        if(dataStats.data === null){
          this.stats = this.getStatsDefault();
          this.createChart(lang);
          this.drawStats.removeSpinner();
        }else{
          if (localStorage.getItem(varLocation)){
            this.fillVisionadosLocations(dataStats);
            this.createChart(lang);
            setTimeout(()=>{                     
              this.drawStats.removeSpinner();
          }, 10);
          }else{
            localStorage.setItem(varLocation,JSON.stringify(dataStats))
            this.fillVisionadosLocations(dataStats);
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }
        }
      });
      }else{
        let date:string = this.filter.getDateCurrentValue();
        if (date!=='0'){
        let newDate = new Date(date);
        let id = this.filter.getBrandCurrentValue();
        let lang = this.filter.getLangCurrentValue();
        this.visionadosService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),id,"sticker").subscribe((dataStats) => {
          if(dataStats.data === null){
            this.drawStats.removeSpinner();
            this.stats = this.getStatsDefault();
            this.createChart(lang);
          
          }else{
           
            this.fillVisionados(dataStats);
            this.createChart(lang);
            this.drawStats.removeSpinner();
            
          }
        });
      }
      }
    });
    this.customLangSubscription = this.filter.customLang.subscribe(lang =>{
      this.createChart(lang);
    });
  }
  ngOnDestroy(): void{
    // this.customBrandSubscription.unsubscribe();
    this.customDateSubscription.unsubscribe();
    this.customLocationSubscription.unsubscribe()
    // this.customStickerSubscription.unsubscribe();
    this.customLangSubscription.unsubscribe();
  }
  
  createChart(lang: any){
    this.canvas = document.getElementById('myChart');
    if(this.canvas){
      this.canvas.remove();
    }
    this.newCanvas = document.createElement("canvas");
    this.newCanvas.setAttribute("id", "myChart");
    this.newCanvas.setAttribute("width", 620);
    this.newCanvas.setAttribute("height", 400);
    this.newCanvas.setAttribute("class", "grafic");
    this.divCanvas = document.getElementById("canvas2");
    this.divCanvas.appendChild(this.newCanvas);
    this.canvas = this.newCanvas;

    this.ctx = this.canvas.getContext('2d');
    let chartdata = this.dataChart(this.stats, lang);

    new Chart(this.ctx, {
      type: 'bar',
      data: chartdata,
      options: {
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{ stacked: true }]
        }
      }
    });

  }
   dataChart(stats:any, lang:any){
    let search  = [];
    let map = [];
    let month: any =[];
    let labelSearch = this.langMonthService.langSearch(lang);
    let labelMap = this.langMonthService.langMap(lang);

    for (let i in stats.historical){
      search.push(stats.historical[i].search);
      map.push(stats.historical[i].map);
      month.push(this.langMonthService.langMonth(stats.historical[i].month,stats.historical[i].year, lang));
    }

    let chartdata = {
      labels: month,
      datasets: [
        {
          label: labelSearch,
          data: search,
          backgroundColor: '#ffb600'
        },
        {
          label:labelMap,
          data: map,
          backgroundColor: '#f9d97b'

        }
      ]
    }
    return chartdata;
  }
  fillVisionados(data:any){
    let historical = [] as any;
    let table = [] as any;
    this.stats.month = data.data.views.month;
    this.stats.month_percent = data.data.views.month_percent
    this.stats.average_location = data.data.views.average_location.toFixed(2)
    this.stats.month_year_last_percent = data.data.views.month_year_last_percent
    this.stats.search = data.data.views.search
    this.stats.search_percent = data.data.views.search_percent
    this.stats.maps = data.data.views.maps
    this.stats.maps_percent= data.data.views.maps_percent
    this.stats.historical = historical
    for(let i in data.data.views.historical){
      let newHistorical={
        month: data.data.views.historical[i].month,
        year: data.data.views.historical[i].year,
        search: data.data.views.historical[i].search.replace('.',''),
        map: data.data.views.historical[i].maps.replace('.','')
      };
      historical.push(newHistorical);
    }
    this.stats.table = table
    for(let i in data.data.views.table){
      let newTable={
        ranking: data.data.views.table[i].ranking,
        code: data.data.views.table[i].code,
        description: data.data.views.table[i].description,
        total: data.data.views.table[i].total,
        search: data.data.views.table[i].search,
        map: data.data.views.table[i].maps
      };
      table.push(newTable);
    }

  }
  fillVisionadosLocations(data:any){
    let historical = [] as any;
    let table = [] as any;
    this.stats.month = data.data["views-average-month"].formatted;
    this.stats.month_percent = this.fixedNumbers(data.data["views-compare-last-month"].original);
    this.stats.average_location = this.fixedNumbers(data.data["views-compare-last-year"].original);
    this.stats.month_year_last_percent = this.fixedNumbers(data.data["views-compare-last-year"].original);
    this.stats.search = data.data["views-searches-month"].formatted;
    this.stats.search_percent = this.fixedNumbers(data.data["views-searches-compare-last-month"].original);
    this.stats.maps = data.data["views-maps-month"].formatted;
    this.stats.maps_percent= this.fixedNumbers(data.data["views-maps-compare-last-month"].original);
    this.stats.historical = historical;

    let arrayFechas = Object.keys(data.data["views-data-graph"].data);
    let dataDirecteIndirect = data.data["views-data-graph"].data;
    for(let n in arrayFechas){
      let fechaEspecifica = arrayFechas[n];
      let dataFechas = fechaEspecifica.split("-")
      let newHistorical={
        month: dataFechas[1],
        year: dataFechas[0],
        search: dataDirecteIndirect[fechaEspecifica].searches.original,
        map: dataDirecteIndirect[fechaEspecifica].maps.original,
      };
      historical.push(newHistorical);
    }
    this.stats.table = null;
  }

  fixedNumbers(data:any){
    let number = data*100
    return number.toFixed(2)
  }

  getStatsDefault(){
    return{
      month:0,
      month_percent:0,
      average_location:0,
      month_year_last_percent:0,
      search: 0,
      search_percent: 0,
      maps: 0,
      maps_percent: 0,
      historical:[
        {
          month: 0,
          year: 0,
          search: 0,
          map: 0,
        },
        {
          month: 0,
          year: 0,
          search: 0,
          map: 0,
        },
        {
          month: 0,
          year: 0,
          search: 0,
          map: 0,
        },
    ],
      table: [
        {
          ranking: 0,
          code: 0,
          description: "",
          total: 0,
          search: 0,
          map: 0,
        },
        {
          ranking: 0,
          code: 0,
          description: "",
          total: 0,
          search: 0,
          map: 0,
        },
        {
          ranking: 0,
          code: 0,
          description: "",
          total: 0,
          search: 0,
          map: 0,
        }
      ]
    }
  }
}
