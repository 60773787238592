import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Chart from 'chart.js';
import { FilterService } from 'src/app/service/filter.service';
import { LangMonthService } from 'src/app/service/lang-month.service';
import { ReputacionService } from 'src/app/service/reputacion.service';
import { Reputacion } from './reputacion.model';
import {StatsAnualesService} from 'src/app/service/stats-anuales.service';
import { DrawStatsService} from 'src/app/service/draw-stats.service';
import { Subscription } from 'rxjs';
/* eslint-disable */
@Component({
  selector: 'app-reputacion',
  templateUrl: './reputacion.component.html',
  styleUrls: ['./reputacion.component.scss']
})
export class ReputacionComponent implements OnInit {
  canvas: any;
  newCanvasGlobal: any;
  chartUpdateGlobal:any;
  divCanvasGlobal:any;
  ctx: any;
  canvas2: any;
  newCanvasMonth:any;
  chartUpdateMonth:any;
  divCanvasMonth:any;
  ctx2: any;
  stats: Reputacion;
  date: string;
  editDate: string;
  drawStats: DrawStatsService;

  public customLocationSubscription: Subscription;
  public customDateSubscription: Subscription;
  public customLangSubscription: Subscription;

  constructor(
    public router: Router,
    public langMonthService: LangMonthService,
    public reputacionService: ReputacionService,
    public statsAnualesService: StatsAnualesService,
    private drawStatsService: DrawStatsService,
    private filter:FilterService
    ) {
      if (!localStorage.getItem('access_token')){
        this.router.navigate(['']);
      }
     this.drawStats = new DrawStatsService();
     this.stats = this.getStatsDefault();
}  

ngOnInit(): void {
  this.customDateSubscription = this.filter.customDate.subscribe(date => {
    if (date!="0"){
      this.drawStats.addSpinner();
      let newDate = new Date(date);
      let lang = this.filter.getLangCurrentValue();
      let id = this.filter.getBrandCurrentValue();
      let type = this.filter.getTypeCurrentValue();
      let idLocation = this.filter.getLocationCurrentValue();
      if(idLocation == '0'){
        this.reputacionService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),id,"sticker").subscribe((dataStats) => {
          if(dataStats.data === null){
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }else{
            this.fillReputation(dataStats);
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }
        });
      }else{
        this.statsAnualesService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),idLocation).subscribe((dataStats) => {
         
          if(dataStats.data === null){
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }else{
            this.fillReputationLocations(dataStats);
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }
        });
      }
    }
  }); 
  this.customLocationSubscription = this.filter.customLocation.subscribe(locationId => {
    if (locationId!="0"){
    let date:string = this.filter.getDateCurrentValue();
    let newDate = new Date(date);
    let lang = this.filter.getLangCurrentValue();
    let month = newDate.getMonth()+1
    let varLocation = 'location_'+locationId+'-'+month+'-'+newDate.getFullYear();
    this.statsAnualesService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),locationId).subscribe((dataStats) => {
      if(dataStats.data === null){
        this.stats = this.getStatsDefault();
        this.createChart(lang);
        this.drawStats.removeSpinner();
      }else{
        if (localStorage.getItem(varLocation)){
          this.fillReputationLocations(dataStats);
          this.createChart(lang);
          setTimeout(()=>{                     
            this.drawStats.removeSpinner();
        }, 10);
        }else{
          localStorage.setItem(varLocation,JSON.stringify(dataStats))
          this.fillReputationLocations(dataStats);
          this.createChart(lang);
          this.drawStats.removeSpinner();
        }
      }
    });
    }else{
      let date:string = this.filter.getDateCurrentValue();
      if (date!=='0'){
      let newDate = new Date(date);
      let lang = this.filter.getLangCurrentValue();
      let id = this.filter.getBrandCurrentValue();
      this.reputacionService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),id,"sticker").subscribe((dataStats) => {
        if(dataStats.data === null){
          this.stats = this.getStatsDefault();
          this.createChart(lang);
          this.drawStats.removeSpinner();
        }else{
          this.fillReputation(dataStats);
          this.createChart(lang);
          this.drawStats.removeSpinner();
        }
      });
    }
    }
  });
}
ngOnDestroy(): void{
  this.customDateSubscription.unsubscribe();
  this.customLocationSubscription.unsubscribe()
  // this.customLangSubscription.unsubscribe();
}
  createChart(lang: any){
    this.canvas = document.getElementById('chartReputationGlobal');
    this.canvas2 = document.getElementById('chartReputationMonth');
    if(this.canvas){
      this.canvas.remove();
      this.newCanvasGlobal = document.createElement("canvas");
      this.newCanvasGlobal.setAttribute("id", "chartReputationGlobal");
      this.newCanvasGlobal.setAttribute("width", 620);
      this.newCanvasGlobal.setAttribute("height", 400);
      this.newCanvasGlobal.setAttribute("class", "grafic");
      this.divCanvasGlobal = document.getElementById("canvasGlobal");
      this.divCanvasGlobal.appendChild(this.newCanvasGlobal);
     
        this.canvas = this.newCanvasGlobal;
        this.ctx = this.canvas.getContext('2d');
    
        let dataChartGlobal = this.dataChartGlobal(this.stats, lang);
    
        this.chartUpdateGlobal=  new Chart(this.ctx, {
          type: 'doughnut',
          options: {
              cutoutPercentage: 70
          },
          data: dataChartGlobal
        });
      
    };
    if(this.canvas2){
      this.canvas2.remove();
      this.newCanvasMonth = document.createElement("canvas");
      this.newCanvasMonth.setAttribute("id", "chartReputationMonth");
      this.newCanvasMonth.setAttribute("width", 620);
      this.newCanvasMonth.setAttribute("height", 400);
      this.newCanvasMonth.setAttribute("class", "grafic");
      this.divCanvasMonth = document.getElementById("canvasMonth");
      this.divCanvasMonth.appendChild(this.newCanvasMonth);
     
        this.canvas2 = this.newCanvasMonth;
        this.ctx2 = this.canvas2.getContext('2d');
    
        let dataChartMonth = this.dataChartMonth(this.stats, lang);
    
        this.chartUpdateMonth=  new Chart(this.ctx2, {
          type: 'doughnut',
          options: {
              cutoutPercentage: 70
          },
          data: dataChartMonth
        });
      
    };

   
    this.ctx = this.canvas.getContext('2d');
    
    this.ctx2 = this.canvas2.getContext('2d');
    let dataChartGlobal = this.dataChartGlobal(this.stats, lang);
    let dataChartMonth = this.dataChartMonth(this.stats, lang);
    
    new Chart(this.ctx, {
      type: 'doughnut',
      options: {
          cutoutPercentage: 70
      },
      data: dataChartGlobal
    });
  
     new Chart(this.ctx2, {
      type: 'doughnut',
      options: {
          cutoutPercentage: 70
      },
      data: dataChartMonth
    });
  }
  
  dataChartGlobal(stats:any, lang: any){
    
    let data =[];
    let labelGood = this.langMonthService.langGood(lang);
    let labelNeutra = this.langMonthService.langNeutra(lang);
    let labelNegative = this.langMonthService.langNegative(lang);
    data.push(stats.global.good.value)
    data.push(stats.global.neutral.value)
    data.push(stats.global.bad.value)
  
      let chartdata = {
        labels: [labelGood,labelNeutra ,labelNegative],
        datasets:[{
          data: data,
          borderWidth: 0,
          backgroundColor: [
            '#49DBA2',
            '#F7C202',
            '#F5426E'
          ]
      }]
      }
      return chartdata;
  }

  dataChartMonth(stats:any, lang: any){
    let data =[];
    let labelGood = this.langMonthService.langGood(lang);
    let labelNeutra = this.langMonthService.langNeutra(lang);
    let labelNegative = this.langMonthService.langNegative(lang);
    data.push(stats.month.good.value)
    data.push(stats.month.neutral.value)
    data.push(stats.month.bad.value)
     
      let chartdata = {
        labels: [labelGood,labelNeutra ,labelNegative],
        datasets:[{
          data: data,
          borderWidth: 0,
          backgroundColor: [
            '#49DBA2',
            '#F7C202',
            '#F5426E'
          ]
      }]
      }
      return chartdata;
}
fillReputationLocations(data: any){
  this.stats.global.total = data.data["reputation-reviews-global"].reviews.original
  this.stats.global.average = data.data["reputation-reviews-global"].score.original
  this.stats.global.good.value = data.data["reputation-global-data-graph"].data.positive.value.original
  this.stats.global.good.percentage = this.fixedNumbers(data.data["reputation-global-data-graph"].data.positive.percent.original)
  this.stats.global.neutral.value = data.data["reputation-global-data-graph"].data.neutral.value.original
  this.stats.global.neutral.percentage = this.fixedNumbers(data.data["reputation-global-data-graph"].data.neutral.percent.original)
  this.stats.global.bad.value = data.data["reputation-global-data-graph"].data.negative.value.original
  this.stats.global.bad.percentage = this.fixedNumbers(data.data["reputation-global-data-graph"].data.negative.percent.original)
  // this.stats.global.table_top = table_top_global
  this.stats.month.table_no_top = null
  this.stats.month.total = data.data["reputation-reviews-month"].reviews.original
  this.stats.month.average = data.data["reputation-reviews-month"].score.original
  this.stats.month.good.value = data.data["reputation-month-data-graph"].data.positive.value.original
  this.stats.month.good.percentage = this.fixedNumbers(data.data["reputation-month-data-graph"].data.positive.percent.original)
  this.stats.month.neutral.value = data.data["reputation-month-data-graph"].data.neutral.value.original
  this.stats.month.neutral.percentage = this.fixedNumbers(data.data["reputation-month-data-graph"].data.neutral.percent.original)
  this.stats.month.bad.value = data.data["reputation-month-data-graph"].data.negative.value.original
  this.stats.month.bad.percentage = this.fixedNumbers(data.data["reputation-month-data-graph"].data.negative.percent.original)
}
fillReputation(data:any){
  let table_top_global = [] as any;
  let table_no_top_global = [] as any;
  let table_top_month = [] as any;
  let table_no_top_month = [] as any;
  this.stats.global.total = data.data.global_reviews.total;
  this.stats.global.average = data.data.global_reviews.average.replace(/,/g, '.')
  this.stats.global.good.value = data.data.global_reviews.good.value
  this.stats.global.good.percentage = data.data.global_reviews.good.percent
  this.stats.global.neutral.value = data.data.global_reviews.neutral.value
  this.stats.global.neutral.percentage = data.data.global_reviews.neutral.percent
  this.stats.global.bad.value = data.data.global_reviews.bad.value
  this.stats.global.bad.percentage = data.data.global_reviews.bad.percent
  this.stats.global.table_top = table_top_global
    for(let i in data.data.global_reviews.table_top){
      let newTable_top ={
        label:data.data.global_reviews.table_top[i].ranking,
        code: data.data.global_reviews.table_top[i].code,
        description: data.data.global_reviews.table_top[i].description,
        score: data.data.global_reviews.table_top[i].score.replace(/,/g, '.'),
        reviews: data.data.global_reviews.table_top[i].reviews,
      }
      table_top_global.push(newTable_top);
    }
  this.stats.global.table_no_top = table_no_top_global
    for(let i in data.data.global_reviews.table_no_top){
      let newTable_no_top={
        label: data.data.global_reviews.table_no_top[i].ranking,
        code: data.data.global_reviews.table_no_top[i].code,
        description: data.data.global_reviews.table_no_top[i].description,
        score: data.data.global_reviews.table_no_top[i].score.replace(/,/g, '.'),
        reviews: data.data.global_reviews.table_no_top[i].reviews,
      }
      table_no_top_global.push(newTable_no_top)
    }
  this.stats.month.total = data.data.month_reviews.total
  this.stats.month.average = data.data.month_reviews.average.replace(/,/g, '.')
  this.stats.month.good.value = data.data.month_reviews.good.value
  this.stats.month.good.percentage = data.data.month_reviews.good.percent
  this.stats.month.neutral.value = data.data.month_reviews.neutral.value
  this.stats.month.neutral.percentage = data.data.month_reviews.neutral.percent
  this.stats.month.bad.value = data.data.month_reviews.bad.value
  this.stats.month.bad.percentage = data.data.month_reviews.bad.percent
  this.stats.month.table_top = table_top_month
    for(let i in data.data.month_reviews.table_top){
      let newTable_top ={
        label: data.data.month_reviews.table_top[i].ranking,
        code: data.data.month_reviews.table_top[i].code,
        description: data.data.month_reviews.table_top[i].description,
        score: data.data.month_reviews.table_top[i].score.replace(/,/g, '.'),
        reviews: data.data.month_reviews.table_top[i].reviews,
      }
      table_top_month.push(newTable_top)
    }
    this.stats.month.table_no_top = table_no_top_month
    for(let i in data.data.month_reviews.table_no_top){
      let newTable_no_top ={
        label: data.data.month_reviews.table_no_top[i].ranking,
        code: data.data.month_reviews.table_no_top[i].code,
        description: data.data.month_reviews.table_no_top[i].description,
        score: data.data.month_reviews.table_no_top[i].score.replace(/,/g, '.'),
        reviews: data.data.month_reviews.table_no_top[i].reviews,
      }
      table_no_top_month.push(newTable_no_top)
    }
}
fixedNumbers(data:any){
  let number = data*100
  return number.toFixed(2)
}

  getStatsDefault(){
    return {
      global:{
        total:0,
        average:0,
        good:{
          value:0,
          percentage:0,
        },
        neutral:{
          value:0,
          percentage:0,
        },
        bad:{
          value:0,
          percentage:0,
        },
        table_top:[
          {
            label: 0,
            code:0,
            description:"0",
            score: 0,
            reviews: 0,
          },
          {
            label: 0,
            code:0,
            description:"0",
            score: 0,
            reviews: 0,
          },
        ]
        ,
        table_no_top:[
          {
            label: 0,
            code:0,
            description:"0",
            score: 0,
            reviews: 0,
          },
          {
            label: 0,
            code:0,
            description:"0",
            score: 0,
            reviews: 0,
          },
        ]
    },
    month:{
      total:0,
      average:0,
      good:{
        value:0,
        percentage:0,
      },
      neutral:{
        value:0,
        percentage:0,
      },
      bad:{
        value:0,
        percentage:0,
      },
      table_top:
      [
        {
          label: 0,
          code:0,
          description:"",
          score: 0,
          reviews: 0,
        },
        {
          label: 0,
          code:0,
          description:"",
          score: 0,
          reviews: 0,
        },
      ],
      
      table_no_top:[
        {
          label: 0,
          code:0,
          description:"",
          score: 0,
          reviews: 0,
        },
        {
          label: 0,
          code:0,
          description:"",
          score: 0,
          reviews: 0,
        },
      ]
  },
    }
  }
}
