import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { topConnect } from '../../environments/environment';
import jwt_decode from 'jwt-decode';
/* eslint-disable */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(public http: HttpClient) { }

  login(email:string,password:string) {
	  
  	let params = {mail: email, password: password};
  	let headers = new HttpHeaders().set('Content-Type','application/json');

  	return new Promise((resolve,rejected) => {
  		this.http.post<any>(topConnect.apiUrl+'login/', params, {headers: headers})
  		.subscribe(
        
  		        (data) => {
  		        localStorage.setItem('access_token', data.token);

                let token:string = localStorage.getItem('access_token')!;
    
                let decoded:any = jwt_decode(token); 
                localStorage.setItem('companyId', decoded.emp);
				localStorage.setItem('stickerId', decoded.sti);

  		        	resolve(data);
  		            console.log("POST call successful value returned in body", 
  		                        data);
  		        },
  		        response => {
  		        	rejected(response);
  		            console.log("POST call in error", response);
  		        },
  		        () => {
  		            console.log("The POST observable is now completed.");
  		        });
  	});

  }
}
