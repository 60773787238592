import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Panel } from './panel.model';
import {BusquedasService} from 'src/app/service/busquedas.service';
import { FilterService } from 'src/app/service/filter.service';
import { VisionadosService } from 'src/app/service/visionados.service';
import { AccionesService } from 'src/app/service/acciones.service';
import { ReputacionService } from 'src/app/service/reputacion.service';
import { TipologiasService } from 'src/app/service/tipologias.service';
import { DrawStatsService} from 'src/app/service/draw-stats.service';
import {StatsAnualesService} from 'src/app/service/stats-anuales.service';
import { Subscription } from 'rxjs';
import { element } from 'protractor';
import { LangMonthService } from 'src/app/service/lang-month.service';
import * as Chart from 'chart.js';
import { ChartDataSets, ChartType, ChartOptions } from 'chart.js';
/* eslint-disable */

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit {
 stats: any;
 drawStats: DrawStatsService;
 public customLocationSubscription: Subscription;
 public customDateSubscription: Subscription;
 arrayStars: Array<number>;
 canvas: any;
 newCanvasGlobal: any;
 chartUpdateGlobal: any;
 divCanvasGlobal: any;
 ctx: any;
 public customLangSubscription: Subscription;



  constructor(
    public router: Router,
    public busquedasService: BusquedasService,
    public visionadosService: VisionadosService,
    public accionesService: AccionesService,
    public reputacionService: ReputacionService,
    public tipologiasService: TipologiasService,
    private drawStatsService: DrawStatsService,
    public langMonthService: LangMonthService,
    public statsAnualesService: StatsAnualesService,
    private filter: FilterService,
    private _route: ActivatedRoute) {
      if (!localStorage.getItem('access_token')){
        this.router.navigate(['']);
      }
    this.drawStats = new DrawStatsService();
    this.arrayStars = [1,2,3,4,5];
    this.stats = this.getStatsDefault();
    }

  ngOnInit(): void {
    // Todos los datos por fecha
    this.customDateSubscription = this.filter.customDate.subscribe(date => {
      if (date!=='0'){
      this.drawStats.addSpinner();
      const newDate = new Date(date);
      const id = this.filter.getBrandCurrentValue();
      const type = this.filter.getTypeCurrentValue();
      const idLocation = this.filter.getLocationCurrentValue();
      let lang = this.filter.getLangCurrentValue();
      if (idLocation =='0'){
        this.busquedasService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),id,'sticker').subscribe((dataStats) => {
          if(dataStats.data === null){
            this.drawStats.removeSpinner();
            this.stats = this.getStatsDefault();
            this.createChart(lang);
          }else{
            this.drawStats.removeSpinner();
            this.fillBusquedas(dataStats);
            this.createChart(lang);
          }
        });
        this.visionadosService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),id,'sticker').subscribe((dataStats) => {
          if(dataStats.data === null){
            this.drawStats.removeSpinner();
            this.stats = this.getStatsDefault();
          }else{
            this.drawStats.removeSpinner();
            this.fillVisionados(dataStats);
          }
        });
        this.accionesService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),id,'sticker').subscribe((dataStats) => {
          if(dataStats.data === null){
            this.drawStats.removeSpinner();
            this.stats = this.getStatsDefault();
          }else{
            this.drawStats.removeSpinner();
            this.fillAcciones(dataStats);
          }
        });
        this.reputacionService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),id,'sticker').subscribe((dataStats) => {
          if(dataStats.data === null){
            this.drawStats.removeSpinner();
            this.stats = this.getStatsDefault();
          }else{
            this.drawStats.removeSpinner();
            this.fillReputacion(dataStats);
          }
        });
        this.tipologiasService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),id,'sticker').subscribe((dataStats) => {
          if(dataStats.data === null){
            this.drawStats.removeSpinner();
            this.stats = this.getStatsDefault();
          }else{
            this.drawStats.removeSpinner();
            this.fillTipologias(dataStats);
          }
        });
  
      }else{
        let month = newDate.getMonth()+1
        let varLocation = 'location_'+idLocation+'-'+month+'-'+newDate.getFullYear();
        this.statsAnualesService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),idLocation).subscribe((dataStats) => {
          
          if(dataStats.data === null){
            this.drawStats.removeSpinner();
            this.stats = this.getStatsDefault();
          }else{
            if (localStorage.getItem(varLocation)){
              this.fillBusquedasLocations(dataStats);
              this.fillVisionadosLocations(dataStats);
              this.fillAccionesLocations(dataStats);
              this.fillReputacionLocations(dataStats);
              this.fillTipologiasLocations(dataStats);
              this.createChartLocations(lang)
              setTimeout(()=>{                     
                this.drawStats.removeSpinner();
            }, 10);
              
            }else{
              localStorage.setItem(varLocation,JSON.stringify(dataStats))
              this.fillBusquedasLocations(dataStats);
              this.fillVisionadosLocations(dataStats);
              this.fillAccionesLocations(dataStats);
              this.fillReputacionLocations(dataStats);
              this.fillTipologiasLocations(dataStats);
              this.createChartLocations(lang)
              this.drawStats.removeSpinner();
              
            }
          }
        });
      }
      }
    });
    //todos los datos por localizacion
    this.customLocationSubscription = this.filter.customLocation.subscribe(locationId => {
      if (locationId!="0"){
      let date:string = this.filter.getDateCurrentValue();
      let newDate = new Date(date);
      let lang = this.filter.getLangCurrentValue();
      let month = newDate.getMonth()+1
        let varLocation = 'location_'+locationId+'-'+month+'-'+newDate.getFullYear();
      this.statsAnualesService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),locationId).subscribe((dataStats) => {
        if(dataStats.data === null){
          this.stats = this.getStatsDefault();
     
        }else{
          if (localStorage.getItem(varLocation)){
            this.fillBusquedasLocations(dataStats);
            this.fillVisionadosLocations(dataStats);
            this.fillAccionesLocations(dataStats);
            this.fillReputacionLocations(dataStats);
            this.fillTipologiasLocations(dataStats);
            this.createChartLocations(lang)
            setTimeout(()=>{                     
              this.drawStats.removeSpinner();
          }, 10);
            
          }else{
            localStorage.setItem(varLocation,JSON.stringify(dataStats))
            this.fillBusquedasLocations(dataStats);
            this.fillVisionadosLocations(dataStats);
            this.fillAccionesLocations(dataStats);
            this.fillReputacionLocations(dataStats);
            this.fillTipologiasLocations(dataStats);
            this.createChartLocations(lang)
            this.drawStats.removeSpinner();
            
          }
        }
      });
      }else{
        let date:string = this.filter.getDateCurrentValue();
        if (date!=='0'){
        let newDate = new Date(date);
        const id = this.filter.getBrandCurrentValue();
        let lang = this.filter.getLangCurrentValue();
        this.busquedasService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),id,'sticker').subscribe((dataStats) => {
          if(dataStats.data === null){
            this.drawStats.removeSpinner();
            this.stats = this.getStatsDefault();
            this.createChart(lang);
          }else{
            this.drawStats.removeSpinner();
            this.fillBusquedas(dataStats);
            this.createChart(lang);
          }
        });
        this.visionadosService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),id,'sticker').subscribe((dataStats) => {
          if(dataStats.data === null){
            this.drawStats.removeSpinner();
            this.stats = this.getStatsDefault();
          }else{
            this.drawStats.removeSpinner();
            this.fillVisionados(dataStats);
          }
        });
        this.accionesService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),id,'sticker').subscribe((dataStats) => {
          if(dataStats.data === null){
            this.drawStats.removeSpinner();
            this.stats = this.getStatsDefault();
          }else{
            this.drawStats.removeSpinner();
            this.fillAcciones(dataStats);
          }
        });
        this.reputacionService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),id,'sticker').subscribe((dataStats) => {
          if(dataStats.data === null){
            this.drawStats.removeSpinner();
            this.stats = this.getStatsDefault();
          }else{
            this.drawStats.removeSpinner();
            this.fillReputacion(dataStats);
          }
        });
        this.tipologiasService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),id,'sticker').subscribe((dataStats) => {
          if(dataStats.data === null){
            this.drawStats.removeSpinner();
            this.stats = this.getStatsDefault();
          }else{
            this.drawStats.removeSpinner();
            this.fillTipologias(dataStats);
          }
        });
      }
    }
      
    });
    this.customLangSubscription = this.filter.customLang.subscribe(lang =>{

      this.createChart(lang);
      this.createChartLocations(lang);
    });
  }
  ngOnDestroy(): void{
    this.customLocationSubscription.unsubscribe();
    this.customDateSubscription.unsubscribe();
    this.customLangSubscription.unsubscribe();
  }

  createChart(lang: any){
    this.canvas = document.getElementById('chartReputationPanel');
    if(this.canvas){
      this.canvas.remove();
      this.newCanvasGlobal = document.createElement('canvas');
      this.newCanvasGlobal.setAttribute('id', 'chartReputationPanel');
      this.newCanvasGlobal.setAttribute('width', 400);
      this.newCanvasGlobal.setAttribute('height', 150);
      this.newCanvasGlobal.setAttribute('class', 'grafic');
      this.divCanvasGlobal = document.getElementById('canvasPanel');
      this.divCanvasGlobal.appendChild(this.newCanvasGlobal);

        this.canvas = this.newCanvasGlobal;
        this.ctx = this.canvas.getContext('2d');

        let dataChartGlobal = this.dataChartDevices(this.stats, lang);

        this.chartUpdateGlobal=  new Chart(this.ctx, {
          type: 'pie',
          data: dataChartGlobal
        });

        // Chart.pluginService.register({
        //   beforeDraw: function(chart) {
        //     console.log(chart.chart.width)
        //     var width = chart.chart.width,
        //         height = chart.chart.height,
        //         ctx = chart.chart.ctx;
        
        //     ctx.restore();
        //     var fontSize = (height / 114).toFixed(2);
        //     ctx.font = fontSize + "em sans-serif";
        //     ctx.textBaseline = "middle";
        
        //     var text = "75%",
        //         textX = Math.round((width - ctx.measureText(text).width) / 2),
        //         textY = height / 1.7;
        
        //     ctx.fillText(text, textX, textY);
        //     ctx.save();
        //   }
        // });

    };

    this.ctx = this.canvas.getContext('2d');
  }

  dataChartDevices(stats:any, lang: any){
    let data =[];
    let labelMobile = this.langMonthService.langMobile(lang);
    let labelDesktop = this.langMonthService.langDesktop(lang);
    data.push(stats.busquedas.mobile_graph_panel.replaceAll('.', ''));
    data.push(stats.busquedas.desktop_graph_panel.replaceAll('.', ''));
  
      let chartdata = {
        labels: [labelMobile,labelDesktop],
        datasets:[{
          data: data,
          borderWidth: 0,
          backgroundColor: [
            '#1d2a41',
            '#e6798b'
          ]
      }]
      }
      return chartdata;
  }

  createChartLocations(lang: any){
   
    this.canvas = document.getElementById('chartReputationPanel');
    if(this.canvas){
      this.canvas.remove();
      this.newCanvasGlobal = document.createElement('canvas');
      this.newCanvasGlobal.setAttribute('id', 'chartReputationPanel');
      this.newCanvasGlobal.setAttribute('width', 400);
      this.newCanvasGlobal.setAttribute('height', 150);
      this.newCanvasGlobal.setAttribute('class', 'grafic');
      this.divCanvasGlobal = document.getElementById('canvasPanel');
      this.divCanvasGlobal.appendChild(this.newCanvasGlobal);

        this.canvas = this.newCanvasGlobal;
        this.ctx = this.canvas.getContext('2d');

        let dataChartGlobal = this.dataChartDevicesLocations(this.stats, lang);

        this.chartUpdateGlobal=  new Chart(this.ctx, {
          type: 'pie',
          data: dataChartGlobal
        });

        // Chart.pluginService.register({
        //   beforeDraw: function(chart) {
        //     console.log(chart.chart.width)
        //     var width = chart.chart.width,
        //         height = chart.chart.height,
        //         ctx = chart.chart.ctx;
        
        //     ctx.restore();
        //     var fontSize = (height / 114).toFixed(2);
        //     ctx.font = fontSize + "em sans-serif";
        //     ctx.textBaseline = "middle";
        
        //     var text = "75%",
        //         textX = Math.round((width - ctx.measureText(text).width) / 2),
        //         textY = height / 1.7;
        
        //     ctx.fillText(text, textX, textY);
        //     ctx.save();
        //   }
        // });

    };

    this.ctx = this.canvas.getContext('2d');
  }

  dataChartDevicesLocations(stats:any, lang: any){
    let data =[];
    let labelMobile = this.langMonthService.langMobile(lang);
    let labelDesktop = this.langMonthService.langDesktop(lang);
    data.push(stats.busquedas.mobile_graph_panel.replaceAll('.', ''));
    data.push(stats.busquedas.desktop_graph_panel.replaceAll('.', ''));
  
      let chartdata = {
        labels: [labelMobile,labelDesktop],
        datasets:[{
          data: data,
          borderWidth: 0,
          backgroundColor: [
            '#1d2a41',
            '#e6798b'
          ]
      }]
      }
      return chartdata;
  }
  fillBusquedas(data: any){
    this.stats.busquedas.mobile = data.data.devices.devices_mobile_month;
    this.stats.busquedas.desktop = data.data.devices.devices_desktop_month;
    this.stats.busquedas.mobile_percent = data.data.devices.devices_mobile_compare_last_month;
    this.stats.busquedas.desktop_percent = data.data.devices.devices_desktop_compare_last_month;
    this.stats.busquedas.mobile_graph_panel= data.data.devices.devices_mobile_month
    this.stats.busquedas.desktop_graph_panel= data.data.devices.devices_desktop_month
  };
  fillVisionados(data: any){
    this.stats.visionados.month = data.data.views.month;
    this.stats.visionados.month_percent = data.data.views.month_percent;
    this.stats.visionados.average_location = data.data.views.average_location;
    this.stats.visionados.month_year_last_percent = data.data.views.month_year_last_percent;
    this.stats.visionados.search = data.data.views.search;
    this.stats.visionados.search_percent = data.data.views.search_percent;
    this.stats.visionados.maps = data.data.views.maps;
    this.stats.visionados.maps_percent= data.data.views.maps_percent;
  }
  fillAcciones(data:any){
    this.stats.acciones.month = data.data.actions.month;
    this.stats.acciones.month_percent = data.data.actions.month_percent;
    this.stats.acciones.average_location = data.data.actions.average_location;
    this.stats.acciones.month_year_last_percent = data.data.actions.month_year_last_percent;
    this.stats.acciones.web = data.data.actions.web;
    this.stats.acciones.web_percent = data.data.actions.web_percent;
    this.stats.acciones.gps = data.data.actions.gps;
    this.stats.acciones.gps_percent = data.data.actions.gps_percent;
    this.stats.acciones.calls = data.data.actions.calls;
    this.stats.acciones.calls_percent = data.data.actions.calls_percent;
  }
  fillReputacion(data: any){
    this.stats.reputacion.global.total = data.data.global_reviews.total;
    this.stats.reputacion.global.average= data.data.global_reviews.average.replace(/,/g, '.');
    this.stats.reputacion.month.total = data.data.month_reviews.total;
    this.stats.reputacion.month.average = data.data.month_reviews.average.replace(/,/g, '.');
  }
  fillTipologias(data:any){
    const typologies = [];
    for (let i in data.data.typologies){
      let newTypology = {
          name: data.data.typologies[i].name,
          global: {
            average: data.data.typologies[i].global.average,
          },
        };
      typologies.push(newTypology);
    }
    this.stats.analisis.typologies = typologies;
  }
  fillBusquedasLocations(data: any){
    this.stats.busquedas.mobile = data.data['devices-mobile-month'].formatted;
    this.stats.busquedas.mobile_percent = this.fixedNumbers(data.data['devices-mobile-month-percent'].original);
    this.stats.busquedas.desktop= data.data['devices-desktop-month'].formatted;
    this.stats.busquedas.desktop_percent = this.fixedNumbers(data.data['devices-desktop-month-percent'].original);
    this.stats.busquedas.direct = data.data['searches-direct-month'].formatted;
    this.stats.busquedas.direct_percent = this.fixedNumbers(data.data['searches-direct-compare-last-month'].original);
    this.stats.busquedas.indirect = data.data['searches-indirect-month'].formatted;
    this.stats.busquedas.indirect_percent = this.fixedNumbers(data.data['searches-indirect-compare-last-month'].original);
    this.stats.busquedas.mobile_graph_panel= data.data['devices-mobile-month'].formatted
    this.stats.busquedas.desktop_graph_panel= data.data['devices-desktop-month'].formatted
  };
  fillVisionadosLocations(data: any){
    this.stats.visionados.month = data.data["views-average-month"].formatted;
    this.stats.visionados.month_percent = this.fixedNumbers(data.data["views-compare-last-month"].original);
    this.stats.visionados.average_location = data.data["views-compare-last-year"].original;;
    this.stats.visionados.month_year_last_percent = this.fixedNumbers(data.data["views-compare-last-year"].original);
    this.stats.visionados.search = data.data["views-searches-month"].formatted;
    this.stats.visionados.search_percent = this.fixedNumbers(data.data["views-searches-compare-last-month"].original);
    this.stats.visionados.maps = data.data["views-maps-month"].formatted;
    this.stats.visionados.maps_percent= this.fixedNumbers(data.data["views-maps-compare-last-month"].original);
  }
  fillAccionesLocations(data:any){
    this.stats.acciones.month = data.data["actions-average-month"].formatted;
    this.stats.acciones.month_percent = this.fixedNumbers(data.data["actions-compare-last-month"].original);
    this.stats.acciones.average_location = data.data["actions-compare-last-year"].original;
    this.stats.acciones.month_year_last_percent = this.fixedNumbers(data.data["actions-compare-last-year"].original);
    this.stats.acciones.web = data.data["actions-web-month"].formatted;
    this.stats.acciones.web_percent = this.fixedNumbers(data.data["actions-web-compare-last-month"].original);
    this.stats.acciones.gps = data.data["actions-direction-month"].formatted; 
    this.stats.acciones.gps_percent = this.fixedNumbers(data.data["actions-direction-compare-last-month"].original);
    this.stats.acciones.calls = data.data["actions-phone-month"].formatted;
    this.stats.acciones.calls_percent = this.fixedNumbers(data.data["actions-phone-compare-last-month"].original);
  }
  fillReputacionLocations(data: any){
    this.stats.reputacion.global.total = data.data["reputation-reviews-global"].reviews.formatted;
    this.stats.reputacion.global.average= data.data["reputation-reviews-global"].score.original.toFixed(2);
    this.stats.reputacion.month.total = data.data["reputation-reviews-month"].reviews.formatted;
    this.stats.reputacion.month.average = data.data["reputation-reviews-month"].score.original.toFixed(2);
    // this.stats.reputacion.month.average = data.data.month_reviews.average.replace(/,/g, '.');
  }
  fillTipologiasLocations(data:any){
    let dataTypologyGolbal = data.data["typology-global"];
    const typologies = [];
    if(dataTypologyGolbal !== null){
      for (let i in dataTypologyGolbal){
        let newTypology = {
          name: dataTypologyGolbal[i].name,
          global: {
            average: dataTypologyGolbal[i].score.original.toFixed(2),
          },
        };
      typologies.push(newTypology);
      }
    }
    this.stats.analisis.typologies = typologies;
  }

  fixedNumbers(data:any){
    let number = data*100
    return number.toFixed(2)
  }

  logOut(){
    localStorage.removeItem('access_token');
    this.router.navigate(['']);
  };
  busquedas(){
    this.router.navigate(['busquedas']);
  };
  visionado(){
    this.router.navigate(['visionados']);
  };
  acciones(){
    this.router.navigate(['acciones']);
  };
  reputacion(){
    this.router.navigate(['reputacion']);
  };
  analisis(){
    this.router.navigate(['analisis']);
  }
  getStatsDefault(){
    return {
      busquedas:{
        mobile:0,
        desktop:0,
        mobile_percent: 0,
        desktop_percent: 0,
        chart_percent:0,
        mobile_graph_panel: "0",
        desktop_graph_panel: "0"
      },
      visionados:{
        month:0,
        month_percent:0,
        average_location:0,
        month_year_last_percent:0,
        search: 0,
        search_percent: 0,
        maps: 0,
        maps_percent: 0
      },
      acciones:{
        month:0,
        month_percent:0,
        average_location:0,
        month_year_last_percent:0,
        web: 0,
        web_percent: 0,
        gps: 0,
        gps_percent: 0,
        calls: 0,
        calls_percent: 0,
      },
      reputacion: {
        global:{
          total: 0,
          total_chart: 0,
          },
        month: {
          total: 0,
          total_chart: 0,
          }
      },
      analisis:[
        {
          name: "",
          global:{
          average:0,
          }
        },
        {
          name: "",
          global:{
          average:0,
          }
        },
        {
          name: "",
          global:{
          average:0,
          }
        },
        {
          name: "",
          global:{
          average:0,
          }
        },
        {
          name: "",
          global:{
          average:0,
          }
        },
        {
          name: "",
          global:{
          average:0,
          }
        },
        {
          name: "",
          global:{
          average:0,
          }
        },
        {
          name: "",
          global:{
          average:0,
          }
        },
        {
          name: "",
          global:{
          average:0,
          }
        },
      ]
    }

    
  }
}