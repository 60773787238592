import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Chart from 'chart.js';
import { BusquedasService } from 'src/app/service/busquedas.service';
import { LangMonthService } from 'src/app/service/lang-month.service';
import { FilterService } from 'src/app/service/filter.service';
import { Busquedas } from './busquedas.model';
import { DrawStatsService} from 'src/app/service/draw-stats.service';
import {StatsAnualesService} from 'src/app/service/stats-anuales.service';
import { Subscription } from 'rxjs';
import { ChartDataSets, ChartType, ChartOptions } from 'chart.js';

/* eslint-disable */

@Component({
  selector: 'app-busquedas',
  templateUrl: './busquedas.component.html',
  styleUrls: ['./busquedas.component.scss']
})

export class BusquedasComponent implements OnInit {
  canvas: any;
  newCanvas: any;
  chartUpdate: any;
  divCanvas: any;
  ctx: any;
  stats: Busquedas;
  date: string;
  editDate: string;
  drawStats: DrawStatsService;
  canvasSearch: any;
  canvasMaps: any;
  newCanvasSearchs: any;
  newCanvasMaps: any;
  chartUpdateSearchs: any;
  chartUpdateMaps: any;
  divCanvasSearchs: any;
  divCanvasMaps: any;
  ctxSearch: any;
  ctxMaps: any;
  br : any

  public customBrandSubscription: Subscription;
  public customLocationSubscription: Subscription;
  public customDateSubscription: Subscription;
  public customStickerSubscription: Subscription;
  public customLangSubscription: Subscription;

  constructor(
    public router: Router,
    public langMonthService: LangMonthService,
    public busquedasService: BusquedasService,
    public statsAnualesService: StatsAnualesService,
    private drawStatsService: DrawStatsService,
    private filter: FilterService
    ) {
      if (!localStorage.getItem('access_token')){
        this.router.navigate(['']);
      }
    this.drawStats = new DrawStatsService();
    this.stats = this.getStatsDefault();
  }

  ngOnInit(): void {
   this.customDateSubscription = this.filter.customDate.subscribe(date => {
    if (date!=='0'){
      this.drawStats.addSpinner();
      const newDate = new Date(date);
      const id = this.filter.getBrandCurrentValue();
      const lang = this.filter.getLangCurrentValue();
      const idLocation = this.filter.getLocationCurrentValue();
      if(idLocation === '0'){
        this.busquedasService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),id,'sticker').subscribe((dataStats) => {
          if(dataStats.data === null){
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.createChartSearch(lang);
            this.drawStats.removeSpinner();
          }else{
  
            this.fillBusquedas(dataStats);
            this.createChart(lang);
            this.createChartSearch(lang);
            this.drawStats.removeSpinner();
          }
        });
      }else{
        this.statsAnualesService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),idLocation).subscribe((dataStats) => {
          if(dataStats.data === null){
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.createChartSearchLocations(lang);
            this.drawStats.removeSpinner();
          }else{
            this.fillBusquedasLocations(dataStats);
            this.createChart(lang);
            this.createChartSearchLocations(lang);
            this.drawStats.removeSpinner();
          }
        });
      }
    }
    });
    this.customLocationSubscription = this.filter.customLocation.subscribe(locationId => {
      if (locationId!=='0'){
      const date: string = this.filter.getDateCurrentValue();
      const newDate = new Date(date);
      const lang = this.filter.getLangCurrentValue();
      const month = newDate.getMonth()+1;
      const varLocation = 'location_'+locationId+'-'+month+'-'+newDate.getFullYear();
      this.statsAnualesService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),locationId).subscribe((dataStats) => {
        if(dataStats.data === null){
          this.stats = this.getStatsDefault();
          this.createChart(lang);
          this.createChartSearchLocations(lang);
          this.drawStats.removeSpinner();
        }else{
          if (localStorage.getItem(varLocation)){
            this.fillBusquedasLocations(dataStats);
            this.createChart(lang);
            this.createChartSearchLocations(lang);
            setTimeout(()=>{
              this.drawStats.removeSpinner();
          }, 10);
          }else{
            localStorage.setItem(varLocation,JSON.stringify(dataStats));
            this.fillBusquedasLocations(dataStats);
            this.createChart(lang);
            this.createChartSearchLocations(lang);
            this.drawStats.removeSpinner();
          }
        }
      });
      }else{
        const date: string = this.filter.getDateCurrentValue();
        if (date!=='0'){
        const newDate = new Date(date);
        const id = this.filter.getBrandCurrentValue();
        const lang = this.filter.getLangCurrentValue();
        this.busquedasService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),id,'sticker').subscribe((dataStats) => {
          if(dataStats.data === null){
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.createChartSearch(lang);
            this.drawStats.removeSpinner();
          }else{
            this.fillBusquedas(dataStats);
            this.createChart(lang);
            this.createChartSearch(lang);
            this.drawStats.removeSpinner();
          }
        });
      }
    }
    });
    this.customLangSubscription = this.filter.customLang.subscribe(lang =>{

      this.createChart(lang);
      this.createChartSearch(lang);
    });
  }
  ngOnDestroy(): void{
    // this.customBrandSubscription.unsubscribe();
    this.customDateSubscription.unsubscribe();
    // this.customStickerSubscription.unsubscribe();
    this.customLocationSubscription.unsubscribe();
    this.customLangSubscription.unsubscribe();
  }

  createChart(lang: any){
    this.canvas = document.getElementById('myChart');
    if(this.canvas){
      this.canvas.remove();
    }
    this.newCanvas = document.createElement("canvas");
    this.newCanvas.setAttribute("id", "myChart");
    this.newCanvas.setAttribute("width", 1020);
    this.newCanvas.setAttribute("height", 400);
    this.newCanvas.setAttribute("class", "grafic");
    this.divCanvas = document.getElementById("canvas1");
    this.divCanvas.appendChild(this.newCanvas);
    this.canvas = this.newCanvas;

    this.ctx = this.canvas.getContext('2d');

    let chartData = this.createDataChart(this.stats, lang);

    let myChart = new Chart(this.ctx, {
      type: 'bar',
      data: chartData,
      options: {
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{ stacked: true }]
        }
      }
    });
  }

  createDataChart(stats: any, lang: any){    
    let desktop = [];
    let mobile = [];
    let month: any =[];
    let labelMobile = this.langMonthService.langMobile(lang);
    let labelPc = this.langMonthService.langDesktop(lang);
    for (let i in stats.historical){
      if(stats.historical[i].mobile && stats.historical[i].desktop  !== "0"){
        mobile.push(stats.historical[i].mobile);
        desktop.push(stats.historical[i].desktop);
        month.push(this.langMonthService.langMonth(stats.historical[i].month,stats.historical[i].year, lang));
      }
    };

    let chartdata = {
      labels: month,
      datasets: [
        {
          label: labelMobile,
          data: mobile,
          backgroundColor: '#1d2a41'
        },
        {
          label:labelPc,
          data: desktop,
          backgroundColor: '#e6798b'

        }
      ]
    }
    return chartdata;
  }

  createChartSearch(lang: any){
   
    this.canvasSearch = document.getElementById('chartSearchs');
    this.canvasMaps = document.getElementById('chartMaps');
    if(this.canvasSearch){
      this.canvasSearch.remove();
      this.newCanvasSearchs = document.createElement('canvas');
      this.newCanvasSearchs.setAttribute('id', 'chartSearchs');
      this.divCanvasSearchs = document.getElementById('canvasSearch');
      this.divCanvasSearchs.appendChild(this.newCanvasSearchs);

        this.canvas = this.newCanvasSearchs;
        this.ctxSearch = this.canvas.getContext('2d');

        let dataChartGlobal = this.dataChartSearchs(this.stats, lang);

        this.chartUpdateSearchs=  new Chart(this.ctxSearch, {
          type: 'pie',
          data: dataChartGlobal
        });

    };

    if(this.canvasMaps){
      this.canvasMaps.remove();
      this.newCanvasMaps = document.createElement("canvas");
      this.newCanvasMaps.setAttribute("id", "chartMaps");
      this.newCanvasMaps.setAttribute("class", "grafic");
      this.divCanvasMaps = document.getElementById("canvasMaps");
      this.divCanvasMaps.appendChild(this.newCanvasMaps);
     
        this.canvasMaps = this.newCanvasMaps;
        this.ctxMaps = this.canvasMaps.getContext('2d');
    
        let dataChartMaps = this.dataChartMaps(this.stats, lang);
    
        this.chartUpdateMaps=  new Chart(this.ctxMaps, {
          type: 'pie',
          data: dataChartMaps
        });
        
      
    };

  }

  dataChartSearchs(stats:any, lang: any){
    let data =[];
    let labelMobile = this.langMonthService.langMobile(lang);
    let labelDesktop = this.langMonthService.langDesktop(lang);
    let mobile_data = stats.search_mobile_graph
    let desktop_data = stats.search_desktop_graph
    data.push(mobile_data)
    data.push(desktop_data)
  
      let chartdata = {
        labels: [labelMobile,labelDesktop],
        datasets:[{
          data: data,
          borderWidth: 0,
          backgroundColor: [
               '#1d2a41',
            '#e6798b'
          ]
      }]
      }
      return chartdata;
  }

  dataChartMaps(stats:any, lang: any){
    let data =[];
    let labelMobile = this.langMonthService.langMobile(lang);
    let labelDesktop = this.langMonthService.langDesktop(lang);
    let mobile_data = stats.maps_mobile_graph
    let desktop_data = stats.maps_desktop_graph
  
    data.push(mobile_data)
    data.push(desktop_data)
  
      let chartdata = {
        labels: [labelMobile,labelDesktop],
        datasets:[{
          data: data,
          borderWidth: 0,
          backgroundColor: [
               '#1d2a41',
            '#e6798b'
          ]
      }]
      }
      return chartdata;
  }

  createChartSearchLocations(lang: any){

    this.canvasSearch = document.getElementById('chartSearchs');
    this.canvasMaps = document.getElementById('chartMaps');
    if(this.canvasSearch){
      this.canvasSearch.remove();
      this.newCanvasSearchs = document.createElement('canvas');
      this.newCanvasSearchs.setAttribute('id', 'chartSearchs');
      this.divCanvasSearchs = document.getElementById('canvasSearch');
      this.divCanvasSearchs.appendChild(this.newCanvasSearchs);

        this.canvas = this.newCanvasSearchs;
        this.ctxSearch = this.canvas.getContext('2d');

        let dataChartGlobal = this.dataChartSearchsLocation(this.stats, lang);

        this.chartUpdateSearchs=  new Chart(this.ctxSearch, {
          type: 'pie',
          data: dataChartGlobal
        });

    };

    if(this.canvasMaps){
      this.canvasMaps.remove();
      this.newCanvasMaps = document.createElement("canvas");
      this.newCanvasMaps.setAttribute("id", "chartMaps");
      this.newCanvasMaps.setAttribute("class", "grafic");
      this.divCanvasMaps = document.getElementById("canvasMaps");
      this.divCanvasMaps.appendChild(this.newCanvasMaps);
     
        this.canvasMaps = this.newCanvasMaps;
        this.ctxMaps = this.canvasMaps.getContext('2d');
    
        let dataChartMaps = this.dataChartMapsLocation(this.stats, lang);
    
        this.chartUpdateMaps=  new Chart(this.ctxMaps, {
          type: 'pie',
          data: dataChartMaps
        });
        
      
    };

  }

  dataChartSearchsLocation(stats:any, lang: any){
    let data =[];
    let labelMobile = this.langMonthService.langMobile(lang);
    let labelDesktop = this.langMonthService.langDesktop(lang);
    data.push(stats.search_mobile_graph)
    data.push(stats.search_desktop_graph)
  
      let chartdata = {
        labels: [labelMobile,labelDesktop],
        datasets:[{
          data: data,
          borderWidth: 0,
          backgroundColor: [
               '#1d2a41',
            '#e6798b'
          ]
      }]
      }
      return chartdata;
  }

  dataChartMapsLocation(stats:any, lang: any){
    let data =[];
    let labelMobile = this.langMonthService.langMobile(lang);
    let labelDesktop = this.langMonthService.langDesktop(lang);
    data.push(stats.maps_mobile_graph)
    data.push(stats.maps_desktop_graph)
  
      let chartdata = {
        labels: [labelMobile,labelDesktop],
        datasets:[{
          data: data,
          borderWidth: 0,
          backgroundColor: [
               '#1d2a41',
            '#e6798b'
          ]
      }]
      }
      return chartdata;
  }


  fillBusquedas(data:any){
    let historical =[] as any;
    this.stats.mobile = data.data.devices.devices_mobile_month;
    this.stats.mobile_percent = data.data.devices.devices_mobile_compare_last_month
    this.stats.desktop = data.data.devices.devices_desktop_month
    this.stats.desktop_percent = data.data.devices.devices_desktop_compare_last_month
    this.stats.maps = data.data.devices.devices_maps_month
    this.stats.search = data.data.devices.devices_searches_month
    this.stats.maps_percent = data.data.devices.views_maps_compare_last_month
    this.stats.search_percent = data.data.devices.views_searches_compare_last_month
    this.stats.search_mobile_graph = data.data.devices.devices_searches_mobile_month.replaceAll('.', '')
    this.stats.search_desktop_graph = data.data.devices.devices_searches_desktop_month.replaceAll('.', '')
    this.stats.maps_mobile_graph = data.data.devices.devices_maps_mobile_month.replaceAll('.', '')
    this.stats.maps_desktop_graph = data.data.devices.devices_maps_desktop_month.replaceAll('.', '')


    this.stats.historical = historical
      for(let i in data.data.devices.historical){
        let newHistorical={
          month: data.data.devices.historical[i].month,
          year: data.data.devices.historical[i].year,
          mobile: data.data.devices.historical[i].mobile.replaceAll('.',''),
          desktop: data.data.devices.historical[i].desktop.replaceAll('.',''),
        };
        historical.push(newHistorical);
      }

  }
  fillBusquedasLocations(data:any){
    const historical =[] as any;
    this.stats.mobile = data.data['devices-mobile-month'].formatted;
    this.stats.mobile_percent = this.fixedNumbers(data.data['devices-mobile-month-percent'].original);
    this.stats.desktop = data.data['devices-desktop-month'].formatted;
    this.stats.desktop_percent = this.fixedNumbers(data.data['devices-desktop-month-percent'].original);
    this.stats.maps = data.data['devices-maps-month'].formatted
    this.stats.maps_percent = this.fixedNumbers(data.data['views-maps-compare-last-month'].original)
    this.stats.search = data.data['devices-searches-month'].formatted
    this.stats.search_percent = this.fixedNumbers(data.data['views-searches-compare-last-month'].original)
    this.stats.search_mobile_graph = data.data['devices-searches-mobile-month'].original
    this.stats.search_desktop_graph = data.data['devices-searches-desktop-month'].original
    this.stats.maps_mobile_graph = data.data['devices-maps-mobile-month'].original
    this.stats.maps_desktop_graph = data.data['devices-maps-desktop-month'].original
    this.stats.historical = historical;

    const arrayFechas = Object.keys(data.data['devices-year-data-graph'].data);
    const dataDirecteIndirect = data.data['devices-year-data-graph'].data;
    for(const n in arrayFechas){
      const fechaEspecifica = arrayFechas[n];
      const dataFechas = fechaEspecifica.split('-');
      const newHistorical={
        month: dataFechas[1],
        year: dataFechas[0],
        mobile: dataDirecteIndirect[fechaEspecifica].mobile.formatted.replaceAll('.',''),
        desktop: dataDirecteIndirect[fechaEspecifica].desktop.formatted.replaceAll('.',''),
      };
      historical.push(newHistorical);
    }

  }
  fixedNumbers(data: any){
    const numberFixed = data*100;
    return numberFixed.toFixed(2);
  }
  getStatsDefault(){
    return {
      month: "0",
      month_percent: "0",
      average_location: "0",
      month_year_last_percent: "0",
      mobile: "0",
      mobile_percent: "0",
      desktop: "0",
      search:"0",
      maps:"0",
      desktop_percent: "0",
      maps_percent: "0",
      search_percent: "0",
      search_mobile_graph: "0", 
      search_desktop_graph: "0", 
      maps_mobile_graph: "0", 
      maps_desktop_graph: "0",
      mobile_graph_panel: "0",
      desktop_graph_panel: "0",
      historical:[
        {
          month: "0",
          year: "0",
          mobile: "0",
          indirect: "0",
        },
        {
          month: "0",
          year: "0",
          mobile: "0",
          indirect: "0",
        },
        {
          month: "0",
          year: "0",
          mobile: "0",
          indirect: "0",
        },
        {
          month: "0",
          year: "0",
          mobile: "0",
          indirect: "0",
        },
        {
          month: "0",
          year: "0",
          mobile: "0",
          indirect:"0",
        },
        {
          month: "0",
          year: "0",
          mobile: "0",
          indirect:"0",
        },
        {
          month: "0",
          year: "0",
          mobile: "0",
          indirect:"0",
        },
        {
          month: "0",
          year: "0",
          mobile: "0",
          indirect:"0",
        },
        {
          month: "0",
          year: "0",
          mobile: "0",
          indirect:"0",
        },
        {
          month: "0",
          year: "0",
          mobile: "0",
          indirect:"0",
        },
        {
          month: "0",
          year: "0",
          mobile: "0",
          indirect:"0",
        },
        {
          month: "0",
          year: "0",
          mobile: "0",
          indirect:"0",
        },
      ],
    };

  }

}
