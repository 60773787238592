import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { CompanyService } from 'src/app/service/company.service';
import { Router } from '@angular/router';
import { FilterService } from 'src/app/service/filter.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;
  showErrorMessage:boolean;

  constructor(
    private authService:AuthService, 
    private companyService: CompanyService,
    public router: Router, 
    private filter: FilterService
    ) {
      this.email = '';
      this.password= '';
      this.showErrorMessage = false;
    }
    
  ngOnInit() {
    if (window.localStorage.getItem('access_token')){
      this.router.navigate(['/panel']);
    }
  }

  onSubmitLogin(){
    
    this.authService.login(this.email,this.password).then(res => {
      this.companyService.getCompanyLogged().subscribe((data) => {
        this.filter.changeCompany(data);
      });
      this.router.navigate(['/panel']);
    }).catch(err => this.showErrorMessage = true)
  }
  
}
