<div class="cortina" id="cortina">
  <span class="spinner hidden" id="loaderSpinner"></span>
<div class="body">
  <app-navegador></app-navegador>
<!-- PANEL DE CONTROL -->
<div class="container-fluid mt-5">
  <div class="row ">
    <!--PRIMERA COLUMNA-->
    <div class="col-md-4 text-center busqueda">
      <div class="row">
        <div class="col-md-12">
          <div>
            <h1 class="top-font-2"><img class="img-title" src="../assets/img/searches.png" alt=""> {{'devices' | translate}}</h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 ">
          <div>
            <div id="canvasPanel" class="col-md chart">
              <canvas id="chartReputationPanel" class="chartReputation"></canvas>
          </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-6 col-6">
          <div class="row">
            <div>
              <div class="col-md-12 ">
                <p class="top-font-5"><img class="img-info" src="../assets/img/ImageMobile.png" alt=""> {{'mobile' | translate}} <span>
                  <p class="top-font-3 bold">{{stats.busquedas.mobile}}</p>
                  <span [ngClass]="drawStats.getClassByNumber(stats.busquedas.mobile_percent)" class="top-font-4 color-red"><img class="img-last-month img-down" src="../assets/img/down.png" alt=""><img class="img-last-month img-up" src="../assets/img/up.png" alt=""> {{stats.busquedas.mobile_percent}}%</span>
                </span></p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-6">
          <div class="row">
            <div class="col-md-12">
              <p class="top-font-5"><img class="img-info" src="../assets/img/ImagePC.png" alt=""> {{'desktop' | translate}} <span>
                <p  class="top-font-3 bold">{{stats.busquedas.desktop}}</p>
                <span [ngClass]="drawStats.getClassByNumber(stats.busquedas.desktop_percent)" class="top-font-4 color-red padding-bottom"><img class="img-last-month img-down" src="../assets/img/down.png" alt=""><img class="img-last-month img-up" src="../assets/img/up.png" alt=""> {{stats.busquedas.desktop_percent}}%</span>
              </span></p>
            </div>
          </div>
        </div>
      </div>      
      <div class="btn-group">
        <button type="submit" class="btn-primary btn-xs btn" (click)="busquedas()">{{'detail' | translate}}</button>
      </div>
    </div>
    

    <!--SEGUNDA COLUMNA-->
    <div class="col-md-4 text-center visionado">
      <div class="row">
        <div class="col-md-12 title-typology">
          <div class="text-title-typology">
            <h1 class="top-font-2"><img class="img-title" src="../assets/img/views.png" alt=""> {{'views' | translate}}</h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p class="top-font-4 bold">{{'total-month' | translate}}</p>
          <h1 class="top-font-1 bold">{{stats.visionados.month}}<span [ngClass]="drawStats.getClassByNumber(stats.visionados.month_percent)" class="top-font-2 color-red padding-bottom"><img class="top-font-2-img img-down" src="../assets/img/down.png" alt=""><img class="top-font-2-img img-up" src="../assets/img/up.png" alt="">  {{stats.visionados.month_percent}}%</span></h1>
          <h3 class="top-font-4 bold">{{'compare-last-year' | translate}}:<span [ngClass]="drawStats.getClassByNumber(stats.visionados.month_year_last_percent)" class="top-font-4 color-red"><img class="img-last-month img-down" src="../assets/img/down.png" alt=""><img class="img-last-month img-up" src="../assets/img/up.png" alt=""> {{stats.visionados.month_year_last_percent}}%</span></h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-6 col-6">
          <div class="row">
            <div class="col-md-12">
              <p class="top-font-5"><img class="img-info" src="../assets/img/search.svg" alt=""> {{'views-searchs' | translate}} <span>
                <p  class="top-font-3 bold">{{stats.visionados.search}}</p>
              <span [ngClass]="drawStats.getClassByNumber(stats.visionados.search_percent)" class="top-font-4 color-red padding-bottom"><img class="img-last-month img-down" src="../assets/img/down.png" alt=""><img class="img-last-month img-up" src="../assets/img/up.png" alt=""> {{stats.visionados.search_percent}}%</span>
              </span></p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-6">
          <div class="row">
            <div class="col-md-12">
              <p class="top-font-5"><img class="img-info" src="../assets/img/google.svg" alt=""> {{'views-maps' | translate}}<span>
                <p  class="top-font-3 bold">{{stats.visionados.maps}}</p>
                <span [ngClass]="drawStats.getClassByNumber(stats.visionados.maps_percent)" class="top-font-4 color-red padding-bottom"><img class="img-last-month img-down" src="../assets/img/down.png" alt=""><img class="img-last-month img-up" src="../assets/img/up.png" alt=""> {{stats.visionados.maps_percent}}%</span>
              </span></p>
            </div>
          </div>
        </div>
      </div>      
      <div class="btn-group">
        <button type="submit" class="btn-primary btn-xs btn" (click)="visionado()">{{'detail' | translate}}</button>
      </div>
    </div>
    <!--TERCERA COLUMNA-->
    <div class="col-md-4  text-center">
      <div class="row">
        <div class="col-md-12 title-typology">
          <div class="text-title-typology">
            <h1 class="top-font-2"><img class="img-title" src="../assets/img/actions.png" alt=""> {{'actions' | translate}}</h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p class="top-font-4 bold">{{'total-month' | translate}}</p>
          <h1 class="top-font-1 bold">{{stats.acciones.month}} <span [ngClass]="drawStats.getClassByNumber(stats.acciones.month_percent)" class="top-font-2 color-red padding-bottom"><img class="top-font-2-img img-down" src="../assets/img/down.png" alt=""><img class="top-font-2-img img-up" src="../assets/img/up.png" alt="">  {{stats.acciones.month_percent}}%</span></h1>
          <h3 class="top-font-4 bold">{{'compare-last-year' | translate}}:<span [ngClass]="drawStats.getClassByNumber(stats.acciones.month_year_last_percent)" class="top-font-4 color-red"><img class="img-last-month img-down" src="../assets/img/down.png" alt=""><img class="img-last-month img-up" src="../assets/img/up.png" alt=""> {{stats.acciones.month_year_last_percent}}%</span></h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-4 col-4">
          <div class="row">
            <div class="col-md-12">
              <p class="top-font-5"><img class="img-info" src="../assets/img/actions-web.png" alt=""> {{'actions-web' | translate}}  <span>
                <p class="top-font-3 bold">{{stats.acciones.web}} </p>
                <span [ngClass]="drawStats.getClassByNumber(stats.acciones.web_percent)"  class="top-font-4 color-red padding-bottom"><img class="img-last-month img-down" src="../assets/img/down.png" alt=""><img class="img-last-month img-up" src="../assets/img/up.png" alt=""> {{stats.acciones.web_percent}}%</span>
              </span></p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-4 ">
          <div class="row">
            <div class="col-md-12">
              <p class="top-font-5"><img class="img-info" src="../assets/img/actions-gps.png" alt=""> {{'actions-indications' | translate}} <span>
                <p class="top-font-3 bold">{{stats.acciones.gps}}</p>
                <span [ngClass]="drawStats.getClassByNumber(stats.acciones.gps_percent)"><img class="img-last-month img-down" src="../assets/img/down.png" alt=""><img class="img-last-month img-up" src="../assets/img/up.png" alt=""> {{stats.acciones.gps_percent}}%</span>
              </span></p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-4">
          <div class="row">
            <div class="col-md-12">
              <p class="top-font-5"><img class="img-info" src="../assets/img/actions-phone.png" alt=""> {{'actions-calls' | translate}} <span>
                <p  class="top-font-3 bold">{{stats.acciones.calls}}</p>
                <span [ngClass]="drawStats.getClassByNumber(stats.acciones.calls_percent)"  class="top-font-4 color-red padding-bottom"><img class="img-last-month img-down" src="../assets/img/down.png" alt=""><img class="img-last-month img-up" src="../assets/img/up.png" alt=""> {{stats.acciones.calls_percent}}%</span>
              </span></p>
            </div>
          </div>
        </div>
      </div>      
      <div class="row btn-group">
        <button type="submit" class="btn-primary btn-xs btn" (click)="acciones()">{{'detail' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<!-- panel de abajo -->

<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-md-6 text-center reputacion">
      <div class="row">
        <div class="col-md-12 title-typology">
          <div class="text-title-typology">
            <h1 class="top-font-2"><img class="img-title" src="../assets/img/reputation.jpg" alt="">{{'reputation' | translate}}</h1>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-6">
          <div class="col-md-12">
            <p class="top-font-4 bold">{{'reviews-total-global' | translate}}</p>
            <h1 class="top-font-1 bold">{{stats.reputacion.global.total}}</h1>
            <p class="top-font-5">{{'average-global' | translate}}:</p> 
            <div class="starPanel">
              <h3 class="top-font-3 bold">{{drawStats.parseDecimales(stats.reputacion.global.average)}}</h3>
              <div *ngFor="let number of arrayStars"><img [src]='drawStats.getStarPanel(stats.reputacion.global.average,number)'></div>
            </div>
          
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-6">
          <div class="col-md-12">
            <p class="top-font-4 bold">{{'reviews-total-month' | translate}}</p>
            <h1 class="top-font-1 bold">{{stats.reputacion.month.total}}</h1>
            <p class="top-font-5">{{'reviews-total-month' | translate}}:</p> 
            <div class="starPanel">
              <h3 class="top-font-3 bold"> {{drawStats.parseDecimales(stats.reputacion.month.average)}}</h3>
              <div *ngFor="let number of arrayStars"><img [src]='drawStats.getStarPanel(stats.reputacion.month.average,number)'></div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-group">
        <button type="submit" class="btn-primary btn-xs btn"(click)="reputacion()">{{'detail' | translate}}</button>
      </div>
    </div>
    <div class="col-md-6 text-center">
      <div class="row">
        <div class="col-md-12 title-typology">
          <div class="text-title-typology">
            <h1 class="top-font-2"><img class="img-title" src="../assets/img/typology.jpg" alt=""> {{'social-listening' | translate}}</h1>
          </div>
        </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12 col-sm-4 col-4">
                <div class="row">
                  <div class="col-md-4 box-typology" *ngFor="let stat of stats.analisis.typologies">
                    <div class="div-typology"><img class="img-typology" [src]='drawStats.getIcon(stat.name,stat.global.average)'></div>
                    <div class="text-typology"> {{stat.name | translate}} <h3 class="top-font-3 bold">{{stat.global.average}}</h3></div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
    </div>
    <div class="btn-group">
      <button type="submit" class="btn-primary btn-xs btn"(click)="analisis()">{{'detail' | translate}}</button>
    </div>
  </div>
</div>

</div>
</div>